import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, List, ListItem, ListItemText, IconButton, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getExpenses, addExpense, deleteExpense, updateExpense } from '../services/expenseService';
import { getBudget } from '../services/budgetService';
import { getSavings } from '../services/savingsService'; // For savings goal notifications

interface Expense {
  _id: string;
  description: string;
  category: string;
  amount: number;
  date: string;
}

interface Budget {
  category: string;
  amount: number;
}

interface Savings {
  _id: string;   // Add an ID to identify savings goals
  goal: string;
  targetAmount: number;
  currentAmount: number;
}

const ExpenseTracker: React.FC = () => {
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [customCategory, setCustomCategory] = useState('');
  const [amount, setAmount] = useState('');
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [savingsGoals, setSavingsGoals] = useState<Savings[]>([]);  // Load multiple savings goals
  const [selectedSavingsGoal, setSelectedSavingsGoal] = useState<string | null>(null);  // Track selected goal
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [notification, setNotification] = useState<string | null>(null); // For budget & savings notifications

  // Fetch budget categories
  const loadCategories = async () => {
    try {
      const budgets = await getBudget();
      const budgetCategories = budgets.map((budget: Budget) => budget.category);
      setCategories(budgetCategories);
    } catch (err) {
      console.error('Failed to load categories', err);
    }
  };

  // Load expenses and categories
  const loadExpenses = async () => {
    const result = await getExpenses();
    setExpenses(result);
  };

  // Load savings goals
  const loadSavingsGoals = async () => {
    const savings = await getSavings();
    setSavingsGoals(savings);
  };

  useEffect(() => {
    loadCategories();
    loadExpenses();
    loadSavingsGoals(); // Load savings goals when component mounts
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const selectedCategory = customCategory || category;

    const expenseData = {
      description,
      category: selectedCategory,
      amount: Number(amount),
      linkToSavingsGoal: selectedSavingsGoal ? selectedSavingsGoal : undefined  // Use selected savings goal or undefined
    };

    try {
      let result;
      if (editingExpense) {
        result = await updateExpense(editingExpense._id, expenseData);
        setSuccess('Expense updated successfully');
      } else {
        result = await addExpense(expenseData);
        setSuccess('Expense added successfully');
      }

      if (result.notification) {
        setNotification(result.notification);
      }

      loadExpenses();
    } catch (err) {
      setError('Failed to save expense');
    }

    setDescription('');
    setCategory('');
    setCustomCategory('');
    setAmount('');
    setSelectedSavingsGoal(null); // Reset savings goal after submission
  };

  const handleDelete = async (id: string) => {
    await deleteExpense(id);
    loadExpenses(); // Reload expenses after deletion
  };

  const handleEdit = (expense: Expense) => {
    setDescription(expense.description);
    setCategory(expense.category);
    setAmount(String(expense.amount));
    setEditingExpense(expense); // Set the expense to be edited
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Expense Tracker
      </Typography>

      {error && (
        <Typography color="error" align="center" gutterBottom>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="primary" align="center" gutterBottom>
          {success}
        </Typography>
      )}

      {/* Notification Snackbar */}
      <Snackbar
        open={!!notification}
        autoHideDuration={6000}
        onClose={() => setNotification(null)}
      >
        <Alert onClose={() => setNotification(null)} severity="warning">
          {notification}
        </Alert>
      </Snackbar>

      <Typography variant="h6" align="center" gutterBottom>
        Your Current Expenses
      </Typography>

      <List>
        {expenses.map((expense, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${expense.description} - $${expense.amount}`}
              secondary={`Category: ${expense.category}`}
            />
            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(expense)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(expense._id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>

      <form onSubmit={handleSubmit}>
        <TextField
          label="Description"
          fullWidth
          margin="normal"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value as string)}
            label="Category"
          >
            {categories.map((cat, index) => (
              <MenuItem key={index} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Or Enter Custom Category"
          fullWidth
          margin="normal"
          variant="outlined"
          value={customCategory}
          onChange={(e) => setCustomCategory(e.target.value)}
        />
        <TextField
          label="Amount"
          type="number"
          fullWidth
          margin="normal"
          variant="outlined"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />

        {/* Savings Goal Selection */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Savings Goal (Optional)</InputLabel>
          <Select
            value={selectedSavingsGoal || ''}
            onChange={(e) => setSelectedSavingsGoal(e.target.value)}
            label="Savings Goal"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {savingsGoals.map((goal) => (
              <MenuItem key={goal._id} value={goal._id}>
                {goal.goal} - Target: ${goal.targetAmount}, Saved: ${goal.currentAmount}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" fullWidth variant="contained" color="primary">
          {editingExpense ? 'Update Expense' : 'Add Expense'}
        </Button>
      </form>
    </Container>
  );
};

export default ExpenseTracker;
