import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, Divider, Box
} from '@mui/material';
import axios from 'axios';

interface Account {
  account_id: string;
  name: string;
  balances: {
    available: number | null;
    iso_currency_code: string;
  };
}

interface Transaction {
  transaction_id: string;
  name: string;
  amount: number;
  iso_currency_code: string;
  date: string;
  logo_url?: string;
}

const AccountsDashboard: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [categorizedTransactions, setCategorizedTransactions] = useState<any>({});

  // Fetch accounts
  const fetchAccounts = async () => {
    try {
      const response = await axios.get('https://apiv0.wealthmate.ca/api/plaid/accounts', {
        headers: { 'x-auth-token': localStorage.getItem('token') || '' },
      });
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  // Fetch transactions
  const fetchTransactions = async () => {
    try {
      const response = await axios.get('https://apiv0.wealthmate.ca/api/plaid/transactions', {
        headers: { 'x-auth-token': localStorage.getItem('token') || '' },
      });

      console.log('Categorized Transactions:', response.data);
      setCategorizedTransactions(response.data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  useEffect(() => {
    fetchAccounts();
    fetchTransactions();
  }, []);

  return (
    <Grid container spacing={3}>

      {/* Linked Accounts Section */}
      <Grid item xs={12}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Your Linked Accounts</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Account Name</strong></TableCell>
                  <TableCell><strong>Available Balance</strong></TableCell>
                  <TableCell><strong>Currency</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map((account) => (
                  <TableRow key={account.account_id}>
                    <TableCell>{account.name}</TableCell>
                    <TableCell>{account.balances.available !== null ? account.balances.available : 'N/A'}</TableCell>
                    <TableCell>{account.balances.iso_currency_code}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>

      {/* Categorized Transactions Section */}
      <Grid item xs={12}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h5" gutterBottom>Recent Transactions</Typography>
            <Divider style={{ marginBottom: '15px' }} />
            {Object.keys(categorizedTransactions).length > 0 ? (
              Object.entries(categorizedTransactions).map(([category, data]: any) => (
                <Box key={category} mb={3}>
                  <Typography variant="h6" style={{ marginBottom: '10px' }}>{category}</Typography>
                  <Grid container spacing={2}>
                    {data.transactions.map((transaction: Transaction) => (
                      <Grid item xs={12} sm={6} md={4} key={transaction.transaction_id}>
                        <Paper elevation={2} style={{ padding: '10px', borderRadius: '8px' }}>
                          {transaction.logo_url && (
                            <img
                              src={transaction.logo_url}
                              alt={transaction.name}
                              style={{ height: 30, marginBottom: 5, borderRadius: 8 }}
                            />
                          )}
                          <Typography variant="subtitle1">{transaction.name}</Typography>
                          <Typography variant="body2">
                            Amount: {transaction.amount} {transaction.iso_currency_code}
                          </Typography>
                          <Typography variant="body2">
                            Date: {transaction.date}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))
            ) : (
              <Typography>No transactions found</Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AccountsDashboard;
