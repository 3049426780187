import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PlaidLink from '../components/PlaidLink';
import axios from 'axios';

const LinkAccountPage: React.FC = () => {
  const [isAccountLinked, setIsAccountLinked] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSuccess = async (token: string) => {
    try {
      const response = await axios.post(
        'https://apiv0.wealthmate.ca/api/plaid/exchange-token',
        { public_token: token },
        {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        }
      );
      setIsAccountLinked(true);
    } catch (error) {
      console.error('Error exchanging public token:', error);
    }
  };

  useEffect(() => {
    const checkIfAccountIsLinked = async () => {
      try {
        const response = await axios.get('https://apiv0.wealthmate.ca/api/plaid/accounts', {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        if (response.data.length > 0) {
          setIsAccountLinked(true);
        }
      } catch (error) {
        console.error('Error checking account status:', error);
      }
    };
    checkIfAccountIsLinked();
  }, []);

  const handleViewAccounts = () => {
    navigate('/accounts-dashboard'); // Navigate to the new dashboard
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Link Your Bank Account
        </Typography>
        {!isAccountLinked ? (
          <PlaidLink onSuccess={handleSuccess} />
        ) : (
          <Typography variant="body1" gutterBottom>
            Congratulations Your bank account is linked!
          </Typography>
        )}

        {isAccountLinked && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleViewAccounts}
            style={{ marginTop: '20px' }}
          >
            View Linked Accounts and Transactions
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default LinkAccountPage;
