import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';

interface Budget {
  category: string;
  amount: number;
}

interface Expense {
  category: string;
  amount: number;
}

const BudgetVsExpensesChart: React.FC = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch budgets
        const budgetsResponse = await axios.get('https://apiv0.wealthmate.ca/api/budget', {
          headers: { 'x-auth-token': localStorage.getItem('token') || '' },
        });

        // Fetch manually added expenses
        const expensesResponse = await axios.get('https://apiv0.wealthmate.ca/api/expenses', {
          headers: { 'x-auth-token': localStorage.getItem('token') || '' },
        });

        // Fetch Plaid transactions
        const transactionsResponse = await axios.get('https://apiv0.wealthmate.ca/api/plaid/transactions', {
          headers: { 'x-auth-token': localStorage.getItem('token') || '' },
        });

        const budgets: Budget[] = budgetsResponse.data;
        const manualExpenses: Expense[] = expensesResponse.data;

        // Transform Plaid transactions into Expense-like objects
        const plaidExpenses: Expense[] = Object.values(transactionsResponse.data).flatMap((categoryData: any) =>
          categoryData.transactions.map((t: any) => ({
            category: t.category[0],
            amount: t.amount,
          }))
        );

        // Combine all expenses
        const allExpenses = [...manualExpenses, ...plaidExpenses];

        // Extract unique categories from both budgets and expenses
        const categories = Array.from(new Set([
          ...budgets.map((budget: Budget) => budget.category),
          ...allExpenses.map((expense: Expense) => expense.category),
        ]));

        // Merge budget and expenses data
        const budgetVsExpenses = categories.map((category: string) => {
          const budget = budgets.find((budget: Budget) => budget.category === category);
          const expenses = allExpenses
            .filter((expense: Expense) => expense.category === category)
            .reduce((total, expense) => total + expense.amount, 0); // Sum expenses for each category

          return {
            category,
            budget: budget ? budget.amount : 0,
            expense: expenses,
          };
        });

        setData(budgetVsExpenses);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="category" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="budget" fill="#82ca9d" name="Budget" />
        <Bar dataKey="expense" fill="#ff7300" name="Expenses" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BudgetVsExpensesChart;
