export const loginUser = async (email: string, password: string) => {
    const response = await fetch('https://apiv0.wealthmate.ca/api/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });
    return response.json();
  };
  
  // export const registerUser = async (email: string, password: string) => {
  //   const response = await fetch('https://apiv0.wealthmate.ca/api/auth/register', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ email, password }),
  //   });
  //   return response.json();
  // };
  
  export const registerUser = async (firstName: string, email: string, password: string) => {
    const response = await fetch('https://apiv0.wealthmate.ca/api/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ firstName, email, password }),
    });
    return response.json();
  };
  