import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Container, Typography, List, ListItem, ListItemText, IconButton, LinearProgress, Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getSavings, addSavings, deleteSavings, updateSavings } from '../services/savingsService';

interface Savings {
  _id: string;
  goal: string;
  targetAmount: number;
  currentAmount: number;
  endDate: string | null;
}

const SavingsManager: React.FC = () => {
  const [goal, setGoal] = useState('');
  const [targetAmount, setTargetAmount] = useState('');
  const [currentAmount, setCurrentAmount] = useState('');
  const [endDate, setEndDate] = useState('');
  const [savings, setSavings] = useState<Savings[]>([]);
  const [editingSavings, setEditingSavings] = useState<Savings | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const loadSavings = async () => {
    const result = await getSavings();
    setSavings(result);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (editingSavings) {
      try {
        await updateSavings(editingSavings._id, goal, Number(targetAmount), Number(currentAmount), endDate);
        setSuccess('Savings goal updated successfully');
        setEditingSavings(null);
        loadSavings(); // Reload savings goals after update
      } catch (err) {
        setError('Failed to update savings goal');
      }
    } else {
      try {
        await addSavings(goal, Number(targetAmount), Number(currentAmount), endDate);
        setSuccess('Savings goal added successfully');
        loadSavings(); // Reload savings goals after adding one
      } catch (err) {
        setError('Failed to add savings goal');
      }
    }

    setGoal('');
    setTargetAmount('');
    setCurrentAmount('');
    setEndDate('');
  };

  const handleDelete = async (id: string) => {
    await deleteSavings(id);
    loadSavings(); // Reload savings goals after deleting one
  };

  const handleEdit = (saving: Savings) => {
    setGoal(saving.goal);
    setTargetAmount(String(saving.targetAmount));
    setCurrentAmount(String(saving.currentAmount));
    setEndDate(saving.endDate ? saving.endDate : '');
    setEditingSavings(saving); // Set the savings goal to be edited
  };

  useEffect(() => {
    loadSavings(); // Load the user's savings goals when the component mounts
  }, []);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Manage Savings Goals
      </Typography>

      {error && (
        <Typography color="error" align="center" gutterBottom>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="primary" align="center" gutterBottom>
          {success}
        </Typography>
      )}

      <Typography variant="h6" align="center" gutterBottom>
        Your Current Savings Goals
      </Typography>
      <List>
        {savings.map((saving, index) => {
          const progress = (saving.currentAmount / saving.targetAmount) * 100; // Calculate progress

          return (
            <ListItem key={index}>
              <ListItemText
                primary={`Goal: ${saving.goal}`}
                secondary={`Target: $${saving.targetAmount} | Saved: $${saving.currentAmount}`}
              />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <LinearProgress variant="determinate" value={progress} />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">{Math.round(progress)}% Saved</Typography>
                </Grid>
              </Grid>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(saving)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(saving._id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          );
        })}
      </List>

      {/* Form to add/edit a savings goal */}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Savings Goal"
          fullWidth
          margin="normal"
          variant="outlined"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        />
        <TextField
          label="Target Amount"
          type="number"
          fullWidth
          margin="normal"
          variant="outlined"
          value={targetAmount}
          onChange={(e) => setTargetAmount(e.target.value)}
        />
        <TextField
          label="Current Amount"
          type="number"
          fullWidth
          margin="normal"
          variant="outlined"
          value={currentAmount}
          onChange={(e) => setCurrentAmount(e.target.value)}
        />
        <TextField
          label="End Date (Optional)"
          type="date"
          fullWidth
          margin="normal"
          variant="outlined"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          {editingSavings ? 'Update Savings Goal' : 'Add Savings Goal'}
        </Button>
      </form>
    </Container>
  );
};

export default SavingsManager;
