// Get all savings goals for the logged-in user
export const getSavings = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch('https://apiv0.wealthmate.ca/api/savings', {
    method: 'GET',
    headers: {
      'x-auth-token': token || '',
    },
  });

  const data = await response.json();

  if (Array.isArray(data)) {
    return data;
  }

  return [];
};

// Add a new savings goal
export const addSavings = async (goal: string, targetAmount: number, currentAmount: number, endDate: string | null) => {
  const token = localStorage.getItem('token');

  const response = await fetch('https://apiv0.wealthmate.ca/api/savings/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token || '',
    },
    body: JSON.stringify({
      goal,
      targetAmount,
      currentAmount,
      endDate,
    }),
  });

  return response.json();
};

// Update a savings goal
export const updateSavings = async (id: string, goal: string, targetAmount: number, currentAmount: number, endDate: string | null) => {
  const token = localStorage.getItem('token');

  const response = await fetch(`https://apiv0.wealthmate.ca/api/savings/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token || '',
    },
    body: JSON.stringify({
      goal,
      targetAmount,
      currentAmount,
      endDate,
    }),
  });

  return response.json();
};

// Delete a savings goal
export const deleteSavings = async (id: string) => {
  const token = localStorage.getItem('token');

  const response = await fetch(`https://apiv0.wealthmate.ca/api/savings/${id}`, {
    method: 'DELETE',
    headers: {
      'x-auth-token': token || '',
    },
  });

  return response.json();
};

// Add a deposit to the savings goal
export const addDeposit = async (id: string, depositAmount: number) => {
  const token = localStorage.getItem('token');

  const response = await fetch(`https://apiv0.wealthmate.ca/api/savings/${id}/deposit`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token || '',
    },
    body: JSON.stringify({
      depositAmount,
    }),
  });

  return response.json();
};

// Link an expense to a savings goal
export const linkExpenseToSavings = async (id: string, expenseId: string) => {
  const token = localStorage.getItem('token');

  const response = await fetch(`https://apiv0.wealthmate.ca/api/savings/${id}/link-expense`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token || '',
    },
    body: JSON.stringify({
      expenseId,
    }),
  });

  return response.json();
};
