export const addBudget = async (budgetData: { category: string; amount: number }) => {
  const token = localStorage.getItem('token');
  
  try {
      const response = await fetch('https://apiv0.wealthmate.ca/api/budget/add', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token || '',
          },
          body: JSON.stringify(budgetData), // 'amount' is now a number
      });
  
      if (!response.ok) {
          throw new Error('Failed to add budget');
      }
  
      return await response.json();
  } catch (error) {
      console.error('Error adding budget:', error);
      throw error; // Re-throw to handle in the UI layer
  }
};

export const getBudget = async () => {
  const token = localStorage.getItem('token');

  try {
      const response = await fetch('https://apiv0.wealthmate.ca/api/budget', {
          method: 'GET',
          headers: {
              'x-auth-token': token || '',
          },
      });
  
      if (!response.ok) {
          throw new Error('Failed to fetch budgets');
      }
  
      const data = await response.json();
  
      // Ensure the response is an array
      if (Array.isArray(data)) {
          return data;
      }
  
      return []; // Fallback to an empty array
  } catch (error) {
      console.error('Error fetching budgets:', error);
      throw error; // Re-throw to handle in the UI layer
  }
};

export const updateBudget = async (id: string, budgetData: { category: string; amount: number }) => {
  const token = localStorage.getItem('token');
  
  try {
      const response = await fetch(`https://apiv0.wealthmate.ca/api/budget/${id}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token || '',
          },
          body: JSON.stringify(budgetData), // Send updated data
      });
  
      if (!response.ok) {
          throw new Error('Failed to update budget');
      }
  
      return await response.json();
  } catch (error) {
      console.error('Error updating budget:', error);
      throw error; // Re-throw to handle in the UI layer
  }
};

export const deleteBudget = async (id: string) => {
  const token = localStorage.getItem('token');
  
  try {
      const response = await fetch(`https://apiv0.wealthmate.ca/api/budget/${id}`, {
          method: 'DELETE',
          headers: {
              'x-auth-token': token || '',
          },
      });
  
      if (!response.ok) {
          throw new Error('Failed to delete budget');
      }
  
      return await response.json();
  } catch (error) {
      console.error('Error deleting budget:', error);
      throw error; // Re-throw to handle in the UI layer
  }
};
