import React, { useState, useContext } from 'react';
import { TextField, Button, Container, Typography, Grid, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginUser } from '../services/authService';
import { useNavigate, Link } from 'react-router-dom';
import Slider from 'react-slick';  // Import react-slick
import { ThemeContext } from '../context/ThemeContext';  // Import the theme context
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';  // Custom styles for the slider

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const response = await loginUser(email.toLowerCase(), password); // Convert email to lowercase here
      if (response.token) {
        localStorage.setItem('token', response.token);  // Save token in localStorage
        navigate('/dashboard');  // Redirect to dashboard
      } else {
        setError(response.message || 'Login failed');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="login-page">
      {/* Login form */}
      <Container maxWidth="sm" className="login-form">
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        {error && (
          <Typography color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!error}
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Logging In...' : 'Login'}
          </Button>
        </form>

        <Grid container justifyContent="center" style={{ marginTop: '16px' }}>
          <Typography variant="body2" color="textSecondary">
            Don't have an account? <Link to="/signup" style={{ color: '#2B4D3D' }}>Sign up here</Link>
          </Typography>
        </Grid>
      </Container>

      {/* Slider section at the bottom */}
      <div className="slider-container">
        <Slider {...sliderSettings}>
          <div className="slider-content">
            <img src="/images/slider-images/1.png" alt="Financial Planning" className="slider-image" />
            <Typography
              variant="h4"
              align="center"
              className="slider-text"
              style={{
                color: '#fff', 
              }}
            >
              Secure your financial future with WealthMate!
            </Typography>
          </div>
          <div className="slider-content">
            <img src="/images/slider-images/2.png" alt="Savings Goals" className="slider-image" />
            <Typography
              variant="h4"
              align="center"
              className="slider-text"
              style={{
                color: '#fff', 
              }}
            >
              Manage budgets, track expenses, and achieve savings goals.
            </Typography>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Login;
