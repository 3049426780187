import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import axios from 'axios';

const COLORS = ['#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#AA72A6', '#AABFF1', '#A7D1E5', '#FF6666'];

interface Expense {
  category: string;
  amount: number;
}

const CategoryBreakdownChart: React.FC = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        // Fetch manually added expenses
        const manualExpensesResponse = await axios.get('https://apiv0.wealthmate.ca/api/expenses', {
          headers: { 'x-auth-token': localStorage.getItem('token') || '' },
        });
        const manualExpenses: Expense[] = manualExpensesResponse.data;

        // Fetch Plaid transactions
        const plaidResponse = await axios.get('https://apiv0.wealthmate.ca/api/plaid/transactions', {
          headers: { 'x-auth-token': localStorage.getItem('token') || '' },
        });

        // Transform Plaid transactions into Expense-like objects
        const plaidExpenses: Expense[] = Object.values(plaidResponse.data).flatMap((categoryData: any) =>
          categoryData.transactions.map((t: any) => ({
            category: t.category[0],
            amount: t.amount,
          }))
        );

        const combinedExpenses = [...manualExpenses, ...plaidExpenses];

        const categoryData: { category: string; total: number }[] = [];

        // Accumulate totals for each category
        combinedExpenses.forEach((expense: Expense) => {
          const category = expense.category;
          const index = categoryData.findIndex((data) => data.category === category);
          if (index > -1) {
            categoryData[index].total += expense.amount;
          } else {
            categoryData.push({ category, total: expense.amount });
          }
        });

        setData(categoryData);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    fetchExpenses();
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="total"
          nameKey="category"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CategoryBreakdownChart;
