export const getUserInfo = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://apiv0.wealthmate.ca/api/user/profile', {
        method: 'GET',
        headers: {
          'x-auth-token': token || '',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }
  
      return response.json();
    } catch (err) {
      console.error('Error fetching user info:', err);
      throw err;
    }
  };
  
  // Making currentPassword and newPassword optional in updateUserInfo
  export const updateUserInfo = async (userInfo: {
    firstName: string;
    email: string;
    currentPassword?: string; 
    newPassword?: string;     
  }) => {
    const token = localStorage.getItem('token');
    const response = await fetch('https://apiv0.wealthmate.ca/api/user/profile', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token || '',
      },
      body: JSON.stringify(userInfo),
    });
  
    if (!response.ok) {
      throw new Error('Failed to update user info');
    }
  
    return response.json();
  };
  