import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, List, ListItem, ListItemText, IconButton, Grid, MenuItem, Select, InputLabel, FormControl, LinearProgress, Box } from '@mui/material';
import { addBudget, getBudget, deleteBudget, updateBudget } from '../services/budgetService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const predefinedCategories = [
  'Rent', 'Groceries', 'Entertainment', 'Utilities', 'Transport', 'Insurance', 'Healthcare', 'Savings', 'Miscellaneous',
];

interface Budget {
  _id: string;
  category: string;
  amount: number;
  spent: number;  // Added to track spent amount
}

const BudgetManager: React.FC = () => {
  const [category, setCategory] = useState('');
  const [customCategory, setCustomCategory] = useState(''); // For custom category
  const [amount, setAmount] = useState('');
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [editingBudget, setEditingBudget] = useState<Budget | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [period, setPeriod] = useState('monthly');  // Add a state for time period

  const loadBudgets = async () => {
    const result = await getBudget();
    setBudgets(result);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const selectedCategory = customCategory || category; // Prefer custom category if provided

    if (editingBudget) {
      try {
        await updateBudget(editingBudget._id, { category: selectedCategory, amount: Number(amount) });
        setSuccess('Budget updated successfully');
        setEditingBudget(null);
        loadBudgets();
      } catch (err) {
        setError('Failed to update budget');
      }
    } else {
      try {
        await addBudget({ category: selectedCategory, amount: Number(amount) });
        setSuccess('Budget added successfully');
        loadBudgets();
      } catch (err) {
        setError('Failed to add budget');
      }
    }

    setCategory('');
    setCustomCategory('');
    setAmount('');
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteBudget(id);
      loadBudgets();
    } catch (error) {
      console.error('Error deleting budget:', error);
    }
  };

  const handleEdit = (budget: Budget) => {
    setCategory(budget.category);
    setAmount(String(budget.amount));
    setEditingBudget(budget);
  };

  useEffect(() => {
    loadBudgets();
  }, []);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>Manage Budget</Typography>

      {error && <Typography color="error" align="center">{error}</Typography>}
      {success && <Typography color="primary" align="center">{success}</Typography>}

      <Typography variant="h6" align="center">Your Current Budgets</Typography>

      <Grid container spacing={2}>
        {budgets.map((budget, index) => {
          const progress = (budget.spent / budget.amount) * 100; // Calculate percentage of budget spent
          const isCloseToLimit = progress >= 90; // Set threshold at 90%

          return (
            <Grid item xs={12} key={index}>
              <ListItem>
                <ListItemText
                  primary={`Category: ${budget.category}`}
                  secondary={`Amount: $${budget.amount}`}
                />
                <Box sx={{ width: '100%', marginTop: 1 }}>
                  {/* Display an alert if budget is close to the limit */}
                  {isCloseToLimit && (
                    <Typography variant="body2" color="error" gutterBottom>
                      You are close to exceeding your budget for {budget.category}!
                    </Typography>
                  )}
                  <LinearProgress variant="determinate" value={progress} sx={{ height: 8, borderRadius: 5 }} />
                  <Typography variant="caption" display="block" gutterBottom>
                    {Math.round(progress)}% used
                  </Typography>
                </Box>

                {/* Edit and delete buttons */}
                <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(budget)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(budget._id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            </Grid>
          );
        })}
      </Grid>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value as string)}
                label="Category"
              >
                {predefinedCategories.map((cat) => (
                  <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Period</InputLabel>
              <Select
                value={period}
                onChange={(e) => setPeriod(e.target.value as string)}
                label="Period"
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Or Enter Custom Category"
              fullWidth
              margin="normal"
              variant="outlined"
              value={customCategory}
              onChange={(e) => setCustomCategory(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Amount"
              type="number"
              fullWidth
              margin="normal"
              variant="outlined"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {editingBudget ? 'Update Budget' : 'Add Budget'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default BudgetManager;
