import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, Paper, Typography } from '@mui/material';
import MonthlyExpenseChart from '../components/MonthlyExpenseChart';
import BudgetVsExpensesChart from '../components/BudgetVsExpensesChart';
import CategoryBreakdownChart from '../components/CategoryBreakdownChart';
import { getUserInfo } from '../services/userService'; // Assuming you have a service to fetch user info

const Dashboard: React.FC = () => {
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = await getUserInfo();
      setFirstName(user.firstName);
    };

    fetchUserInfo();
  }, []);

  return (
    <Container>
      <Box paddingTop={2} paddingBottom={2} >
        <Paper elevation={4}>
          <CardContent>
        <Typography variant="h5" gutterBottom>
          Hi {firstName}, welcome to your Personal Finance Dashboard.
        </Typography>
        </CardContent>
        </Paper>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px' }}>
            <Typography variant="h6" gutterBottom>
              Monthly Expenses
            </Typography>
            <MonthlyExpenseChart />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px' }}>
            <Typography variant="h6" gutterBottom>
              Budget vs. Expenses
            </Typography>
            <BudgetVsExpensesChart />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px' }}>
            <Typography variant="h6" gutterBottom>
              Expense Breakdown by Category
            </Typography>
            <CategoryBreakdownChart />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
