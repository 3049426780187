import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

interface Expense {
  _id?: string;
  description?: string;
  category: string;
  amount: number;
  date: string; // Format 'YYYY-MM-DD'
}

const MonthlyExpenseChart: React.FC = () => {
  const [data, setData] = useState<{ name: string; amount: number }[]>([]);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        // Fetch manually added expenses
        const manualExpensesResponse = await axios.get('https://apiv0.wealthmate.ca/api/expenses', {
          headers: { 'x-auth-token': localStorage.getItem('token') || '' },
        });
        const manualExpenses: Expense[] = manualExpensesResponse.data;

        // Fetch Plaid transactions
        const plaidResponse = await axios.get('https://apiv0.wealthmate.ca/api/plaid/transactions', {
          headers: { 'x-auth-token': localStorage.getItem('token') || '' },
        });

        // Transform Plaid transactions into Expense-like objects
        const plaidExpenses: Expense[] = Object.values(plaidResponse.data).flatMap((categoryData: any) =>
          categoryData.transactions.map((t: any) => ({
            category: t.category[0],
            amount: t.amount,
            date: t.date,
          }))
        );

        const combinedExpenses = [...manualExpenses, ...plaidExpenses];

        const groupedData: { [key: string]: number } = {};

        // Group combined expenses by month
        combinedExpenses.forEach((expense) => {
          const month = format(parseISO(expense.date), 'MMM yyyy'); // Format as 'Jan 2024', etc.
          if (groupedData[month]) {
            groupedData[month] += expense.amount;
          } else {
            groupedData[month] = expense.amount;
          }
        });

        // Transform grouped data into an array suitable for Recharts
        const chartData = Object.keys(groupedData).map((month) => ({
          name: month,
          amount: groupedData[month],
        }));

        setData(chartData);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    fetchExpenses();
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorAmount" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FFC107" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FFC107" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="amount"
          stroke="#FFC107"
          fillOpacity={1}
          fill="url(#colorAmount)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default MonthlyExpenseChart;
