import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import BudgetManager from './components/BudgetManager';
import ExpenseTracker from './components/ExpenseTracker';
import SavingsManager from './components/SavingsManager';
import LandingPage from './pages/LandingPage';
import { AnimatePresence, motion } from 'framer-motion';
import LinkAccountPage from './pages/LinkAccountPage';
import AccountsDashboard from './pages/AccountsDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { ThemeContextProvider } from './context/ThemeContext'; // Add ThemeContext
import UserProfile from './components/UserProfile';

const PageTransitionWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.7 }}
        style={{
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <Router>
        <Navbar />
        <div style={{ position: 'relative' }}>
          <PageTransitionWrapper>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              
              {/* Protected routes */}
              <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
              <Route path="/budget-manager" element={<ProtectedRoute element={<BudgetManager />} />} />
              <Route path="/expense-tracker" element={<ProtectedRoute element={<ExpenseTracker />} />} />
              <Route path="/savings-manager" element={<ProtectedRoute element={<SavingsManager />} />} />
              <Route path="/link-account" element={<ProtectedRoute element={<LinkAccountPage />} />} />
              <Route path="/accounts-dashboard" element={<ProtectedRoute element={<AccountsDashboard />} />} />
              <Route path="/profile" element={<ProtectedRoute element={<UserProfile />} />} /> {/* Protect Profile */}
            </Routes>
          </PageTransitionWrapper>
        </div>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;
