import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Snackbar, Alert } from '@mui/material';
import { getUserInfo, updateUserInfo } from '../services/userService';

const UserProfile: React.FC = () => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    email: '',
    currentPassword: '',
    newPassword: ''
  });
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  // Fetch user info on mount
  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = await getUserInfo();
      setUserInfo({
        firstName: user.firstName || '',
        email: user.email || '',
        currentPassword: '',
        newPassword: ''
      });
    };

    fetchUserInfo();
  }, []);

  // Handle form submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { currentPassword, newPassword, firstName, email } = userInfo;

    // Only include currentPassword and newPassword if the user wants to change the password
    const payload = {
      firstName,
      email,
      ...(currentPassword && newPassword ? { currentPassword, newPassword } : {})
    };

    const response = await updateUserInfo(payload);

    if (response.message === 'User updated successfully') {
      setSnackbarSeverity('success');
      setSnackbarMessage('User information updated successfully');
    } else {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to update user information');
    }

    setIsSnackbarOpen(true);

    // Reset password fields
    setUserInfo({ ...userInfo, currentPassword: '', newPassword: '' });
  };

  // Close the snackbar
  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Profile Settings
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="First Name"
          variant="outlined"
          value={userInfo.firstName}
          onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          variant="outlined"
          value={userInfo.email}
          onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Current Password (optional)"
          type="password"
          variant="outlined"
          value={userInfo.currentPassword}
          onChange={(e) => setUserInfo({ ...userInfo, currentPassword: e.target.value })}
        />
        <TextField
          fullWidth
          margin="normal"
          label="New Password (optional)"
          type="password"
          variant="outlined"
          value={userInfo.newPassword}
          onChange={(e) => setUserInfo({ ...userInfo, newPassword: e.target.value })}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Update Profile
        </Button>
      </form>

      {/* Snackbar for success/error messages */}
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserProfile;
