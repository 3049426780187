import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import axios from 'axios';
import { Button } from '@mui/material';

interface PlaidLinkProps {
  onSuccess: (publicToken: string) => void;
}

const PlaidLink: React.FC<PlaidLinkProps> = ({ onSuccess }) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);

  useEffect(() => {
    const createLinkToken = async () => {
      try {
        const response = await axios.get('https://apiv0.wealthmate.ca/api/plaid/link-token', {
          headers: {
            'x-auth-token': localStorage.getItem('token'), // Send token in 'x-auth-token' header
          },
        });

        setLinkToken(response.data.link_token);
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    };

    createLinkToken();
  }, []);

  const config = {
    token: linkToken ?? '',
    onSuccess: (publicToken: string) => {
      onSuccess(publicToken); // Pass public token to the parent
    },
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <Button variant="contained" sx={{padding:1, marginTop:5}} onClick={() => open()} disabled={!ready}>
      Connect a bank account
    </Button>
  );
};

export default PlaidLink;
