// /frontend/src/services/expenseService.ts
  export const getExpenses = async () => {
    const token = localStorage.getItem('token');
  
    const response = await fetch('https://apiv0.wealthmate.ca/api/expenses', {
      method: 'GET',
      headers: {
        'x-auth-token': token || '',
      },
    });
  
    const data = await response.json();
  
    // Ensure the response is an array
    if (Array.isArray(data)) {
      return data;
    }
  
    return []; // Fallback to an empty array
  };
  
  
  
  // /frontend/src/services/expenseService.ts
export const deleteExpense = async (id: string) => {
    const token = localStorage.getItem('token');
    
    const response = await fetch(`https://apiv0.wealthmate.ca/api/expenses/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token || '',
      },
    });
  
    return response.json();
  };
  
  // /frontend/src/services/expenseService.ts
export const addExpense = async (expenseData: { description: string; category: string; amount: number; linkToSavingsGoal?: string }) => {
  const token = localStorage.getItem('token');

  const response = await fetch('https://apiv0.wealthmate.ca/api/expenses/add', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token || '',
      },
      body: JSON.stringify(expenseData), // Include linkToSavingsGoal
  });

  return response.json();
};

export const updateExpense = async (id: string, expenseData: { description: string; category: string; amount: number; linkToSavingsGoal?: string }) => {
  const token = localStorage.getItem('token');

  const response = await fetch(`https://apiv0.wealthmate.ca/api/expenses/${id}`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token || '',
      },
      body: JSON.stringify(expenseData), // Include linkToSavingsGoal
  });

  return response.json();
};
