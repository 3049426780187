import React, { useState, useEffect, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Menu,
  MenuItem,
  Switch,
  Box,
  Badge,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { ThemeContext } from '../context/ThemeContext'; // Import ThemeContext

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Check if the user is logged in
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false); // State to control the drawer
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for account settings menu
  const [hasLinkedAccount, setHasLinkedAccount] = useState(false); // State to track linked account status
  const open = Boolean(anchorEl);

  // Consume ThemeContext
  const { toggleTheme, isDarkMode } = useContext(ThemeContext);

  // Fetch linked account status when component mounts or token changes
  useEffect(() => {
    const checkLinkedAccount = async () => {
      if (token) {
        try {
          const response = await axios.get('https://apiv0.wealthmate.ca/api/plaid/accounts', {
            headers: { 'x-auth-token': token },
          });
          setHasLinkedAccount(response.data.length > 0);
        } catch (error) {
          console.error('Error checking linked accounts:', error);
        }
      }
    };

    checkLinkedAccount();
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setHasLinkedAccount(false);  // Clear linked account status on logout
    navigate('/login');
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mobileMenu = (
    <List>
      {token && ( // Only show when user is logged in
        <>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/dashboard">
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/budget-manager">
              <ListItemText primary="Budget Manager" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/expense-tracker">
              <ListItemText primary="Expense Tracker" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/savings-manager">
              <ListItemText primary="Savings Manager" />
            </ListItemButton>
          </ListItem>
          {hasLinkedAccount ? (
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/accounts-dashboard">
                <ListItemText primary="Accounts Dashboard" />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/link-account">
                <ListItemText primary="Link Account" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)} // Open drawer on click
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="inherit"
              component={Link}
              to="/"
              aria-label="home"
              sx={{ ml: 1 }}
            >
              <img
                src="/logo.png"
                alt="WealthMate Logo"
                style={{ width: '40px', height: '40px' }}  // Adjust size as needed
              />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)} // Close drawer
            >
              {mobileMenu}
            </Drawer>
          </>
        ) : (
          <>
            <IconButton
              edge="start"
              color="inherit"
              component={Link}
              to="/"
              aria-label="home"
              sx={{ mr: 2 }}
            >
              <img
                src="/logo.png"
                alt="WealthMate Logo"
                style={{ width: '40px', height: '40px' }}  // Adjust size as needed
              />
            </IconButton>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              WealthMate
            </Typography>
          </>
        )}

        {/* Render Login and Signup buttons if user is not logged in */}
        {!isMobile && !token ? (
          <>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <Button color="inherit" component={Link} to="/signup">
              Signup
            </Button>
          </>
        ) : (
          !isMobile && token && (
            <>
              <Button color="inherit" component={Link} to="/dashboard">
                Dashboard
              </Button>
              <Button color="inherit" component={Link} to="/budget-manager">
                Budget Manager
              </Button>
              <Button color="inherit" component={Link} to="/expense-tracker">
                Expense Tracker
              </Button>
              <Button color="inherit" component={Link} to="/savings-manager">
                Savings Manager
              </Button>
            </>
          )
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Switch
          checked={isDarkMode}
          onChange={toggleTheme}
          color="default"
          inputProps={{ 'aria-label': 'toggle dark mode' }}
        />
        {token && (
          <>
            <IconButton color='inherit'>
              <Badge>
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/profile" onClick={handleClose}>
                Profile Settings
              </MenuItem>
              {hasLinkedAccount ? (
                <MenuItem component={Link} to="/accounts-dashboard" onClick={handleClose}>
                  Accounts Dashboard
                </MenuItem>
              ) : (
                <MenuItem component={Link} to="/link-account" onClick={handleClose}>
                  Link Account
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
