import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const heroImage = '/hero.png';

const LandingPage: React.FC = () => {
  const isAuthenticated = !!localStorage.getItem('token'); // Check if user is authenticated

  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)',
        minHeight: '100vh', // Changed from height to minHeight to avoid overflow and ensure full screen coverage
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        overflow: 'hidden', // Prevent any unexpected overflow
      }}
    >
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h2" gutterBottom>
              Personal Finance Manager
            </Typography>
            <Typography variant="h5" gutterBottom>
              Manage your budget, track your expenses, and set savings goals — all in one place.
            </Typography>
            <Grid container spacing={2} justifyContent="center" marginTop={2}>
              {isAuthenticated ? (
                <Grid item>
                  <Button
                    component={Link}
                    to="/dashboard"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Go to Dashboard
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <Button
                      component={Link}
                      to="/signup"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Sign Up
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      to="/login"
                      variant="outlined"
                      color="inherit"
                      size="large"
                    >
                      Log In
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={heroImage}
              alt="Finance Hero"
              style={{ width: '100%', maxWidth: '400px', marginTop: '20px' }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPage;
